.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control {
  width: 100%;
  border: none;
  background: none;
}

.dataHeader {
  border: 1px solid #E0E0E0;
  background-color: #f5f5f5;
  font-weight: bold;
  white-space: nowrap;
  /* width: 240px; */
}
.dataBody {
  border: 1px solid #E0E0E0;  
}

.dataTitle {
  background-color: #E0E0E0;
}

